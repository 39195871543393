<template>
    <div>
        <ts-page-title
            :title="$t('nssfReport.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('nssfReport.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="row">
                    <div class="col-md-3">
                        <div class="row mb-3">
                            <div>
                                <label
                                    class="col-label form-label tw-text-xs required"
                                    >{{
                                        $t('nssfReport.organizationalStructure')
                                    }}</label
                                >
                                <Select
                                    v-model="org_chart_structure_id"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'org_chart_structure_id'
                                        )
                                    }"
                                >
                                    <Option
                                        v-for="(org,
                                        index) in orgChartStructure"
                                        :value="org.org_chart_structure_id"
                                        :key="index"
                                    >
                                        {{ org.org_chart_structure }}
                                    </Option>
                                </Select>
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('org_chart_structure_id')"
                                >
                                    {{ errors.first('org_chart_structure_id') }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <label
                                    class="col-label form-label tw-text-xs required"
                                    >{{ $t('nssfReport.cycleYear') }}</label
                                >
                                <date-Picker
                                    :value="cycle_year"
                                    type="year"
                                    placeholder="Select Year"
                                    format="yyyy"
                                    @on-change="cycleYearChange"
                                    :class="{
                                        'is-invalid': errors.has('cycle_year')
                                    }"
                                ></date-Picker>
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('cycle_year')"
                                >
                                    {{ errors.first('cycle_year') }}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label
                                    class="col-label form-label tw-text-xs required"
                                    >{{ $t('nssfReport.cycleMonth') }}</label
                                >
                                <date-Picker
                                    :value="cycle_month"
                                    type="month"
                                    placeholder="Select Month"
                                    format="MM"
                                    @on-change="cycleMonthChange"
                                    :class="{
                                        'is-invalid': errors.has('cycle_month')
                                    }"
                                ></date-Picker>
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('cycle_month')"
                                >
                                    {{ errors.first('cycle_month') }}
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label
                                class="col-label form-label tw-text-xs required"
                                >{{ $t('nssfReport.exchangeRate') }}</label
                            >
                            <input
                                v-model="exchange_rate"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('exchange_rate')
                                }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('exchange_rate')"
                            >
                                {{ errors.first('exchange_rate') }}
                            </div>
                        </div>
                        <div class="mb-3">
                            <div
                                class="tw-flex tw-justify-end tw-space-x-2 tw-sh tw-mt-3"
                            >
                                <ts-button
                                    @click.prevent="() => $emit('cancel')"
                                    class="btn-gray"
                                >
                                    {{ $t('export') }}
                                </ts-button>
                                <ts-button
                                    color="primary"
                                    :waiting="waiting"
                                    :disabled="waiting_new || waiting"
                                    @click="fetchData"
                                >
                                    {{ $t('view') }}
                                </ts-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 mt-3">
                        <Table
                            :columns="columns"
                            :data="resources"
                            size="small"
                            :loading="loading"
                            class="tw-rounded-md"
                            max-height="700"
                            stripe
                        >
                            <template slot-scope="{ row }" slot="wage_khmer">
                                <strong>{{
                                    formatNumber(row.wage_khmer)
                                }}</strong>
                            </template>
                            <template slot-scope="{ row }" slot="wage_dollar">
                                <strong>{{
                                    formatNumber(row.wage_dollar)
                                }}</strong>
                            </template>
                            <template slot-scope="{ row }" slot="assum">
                                <strong>{{ formatNumber(row.assum) }}</strong>
                            </template>
                            <template slot-scope="{ row }" slot="contribution">
                                <strong>{{
                                    formatNumber(row.contribution)
                                }}</strong>
                            </template>
                        </Table>
                        <div
                            class="tw-flex tw-items-center tw-justify-end tw-p-4"
                        >
                            <ts-i-pagination
                                v-model="pagination"
                                @navigate="fetchData"
                            ></ts-i-pagination>
                        </div>
                    </div>
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState, mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'nssf-report',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            org_chart_structure_id: null,
            exchange_rate: null,
            employee_id: null,
            cycle_year: moment().format('YYYY'),
            cycle_month: moment().format('MM'),
            orgChartStructure: [],
            employeeProfile: []
        }
    },
    computed: {
        ...mapState('report/nssfReport', ['resources', 'pagination']),
        orgChartStructures () {
            return this.$store.state.report.nssfReport.orgChartStructures
        },
        ...mapGetters(['formatNumber']),
        columns () {
            return [
                {
                    title: this.$t('nssfReport.employeeID'),
                    width: '160px',
                    key: 'employee_id',
                    align: 'center'
                },
                {
                    title: this.$t('nssfReport.enterprise'),
                    width: '160px',
                    key: 'enterprise_id',
                    sortable: true
                },
                {
                    title: this.$t('nssfReport.nssf'),
                    width: '130px',
                    key: 'nssf_id'
                },
                {
                    title: this.$t('nssfReport.idNational'),
                    width: '130px',
                    key: 'id_national'
                },
                {
                    title: this.$t('nssfReport.firstNamekh'),
                    width: '170px',
                    key: 'first_name_in_khmer',
                    sortable: true
                },
                {
                    title: this.$t('nssfReport.lastNameKh'),
                    width: '170px',
                    key: 'last_name_in_khmer',
                    sortable: true
                },
                {
                    title: this.$t('nssfReport.firstNameEn'),
                    width: '170px',
                    key: 'first_name_in_english',
                    sortable: true
                },
                {
                    title: this.$t('nssfReport.lastNameEn'),
                    width: '170px',
                    key: 'last_name_in_english',
                    sortable: true
                },

                {
                    title: this.$t('nssfReport.sex'),
                    width: '60px',
                    key: 'sex'
                },
                {
                    title: this.$t('nssfReport.dateOfBirth'),
                    width: '130px',
                    key: 'date_of_birth'
                },
                {
                    title: this.$t('nssfReport.nationality'),
                    width: '130px',
                    key: 'nationality',
                    align: 'center'
                },
                {
                    title: this.$t('nssfReport.hiredDate'),
                    width: '130px',
                    key: 'hired_date'
                },
                {
                    title: this.$t('nssfReport.position'),
                    width: '130px',
                    key: 'position',
                    sortable: true
                },
                {
                    title: this.$t('nssfReport.status'),
                    width: '130px',
                    key: 'status',
                    align: 'center'
                },
                {
                    title: this.$t('nssfReport.wageKhmer'),
                    width: '130px',
                    slot: 'wage_khmer'
                },
                {
                    title: this.$t('nssfReport.wageDollar'),
                    width: '130px',
                    slot: 'wage_dollar'
                },
                {
                    title: this.$t('nssfReport.assum'),
                    width: '130px',
                    slot: 'assum'
                },
                {
                    title: this.$t('nssfReport.contribution'),
                    width: '130px',
                    slot: 'contribution',
                    align: 'center'
                }
            ]
        }
    },
    created () {
        this.fetchResource({
            fnName: 'orgChartStructure'
        })
    },
    methods: {
        ...mapActions('report/nssfReport', ['formViewModel']),
        async fetchResource (attributes) {
            this.loading = true
            let response = await this.formViewModel({
                params: attributes
            })

            if (attributes.fnName) {
                attributes.fnName.split(',').forEach(fn => {
                    this[fn] = response[fn]
                })
            }
            this.loading = false
        },
        cycleYearChange (cycle_year) {
            this.cycle_year = cycle_year
        },
        cycleMonthChange (cycle_month) {
            this.cycle_month = cycle_month
        },
        async fetchData (attributes) {
            this.loading = true
            this.errors = new Errors()
            this.$store
                .dispatch('report/nssfReport/fetch', {
                    org_chart_structure_id: this.org_chart_structure_id,
                    cycle_year: this.cycle_year,
                    cycle_month: this.cycle_month,
                    exchange_rate: this.exchange_rate,
                    ...attributes
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'NSSF REPORT',
                desc: not.text
            })
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('report/nssfReport/RESET_STATE')
        next()
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchResource({
                fnName: 'orgChartStructure'
            })
        })
    }
}
</script>
